.boxselector_wrapper > div,
.boxselector_wrapper box-selector-item {
  --selected-item-color: var(--blue-2);
  flex: 1;
  padding: 5px;
}

.boxselector_wrapper .boxselector_header {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: bold;
  user-select: none;
}

.boxselector_header .fa,
.fab {
  font-weight: normal;
}

.boxselector_wrapper input[type='radio'] {
  display: none;
}

.boxselector_wrapper input[type='radio']:not(:disabled) ~ label {
  cursor: pointer;
  background-color: var(--bg-boxselector-wrapper-disabled-color);
}

.boxselector_wrapper input[type='radio']:not(:disabled):hover ~ label:hover {
  cursor: pointer;
}

.boxselector_wrapper label {
  font-weight: normal;
  font-size: 12px;
  display: block;
  background: var(--bg-boxselector-color);
  border: 1px solid var(--border-boxselector-color);
  border-radius: 2px;
  padding: 10px 10px 0 10px;
  text-align: center;
  box-shadow: var(--shadow-boxselector-color);
  position: relative;
}

.box-selector-item input:disabled + label,
.boxselector_wrapper label.boxselector_disabled {
  background: var(--bg-boxselector-disabled-color) !important;
  border-color: #787878;
  color: #787878;
  cursor: not-allowed;
  pointer-events: none;
}

.boxselector_wrapper input[type='radio']:checked + label {
  background: var(--selected-item-color);
  color: white;
  padding-top: 20px;
  border-color: var(--selected-item-color);
}

.boxselector_wrapper input[type='radio']:checked + label::after {
  color: var(--selected-item-color);
  font-family: 'Font Awesome 5 Free';
  border: 2px solid var(--selected-item-color);
  content: '\f00c';
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  height: 30px;
  width: 30px;
  line-height: 26px;
  text-align: center;
  border-radius: 50%;
  background: white;
  box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 700px) {
  .boxselector_wrapper {
    flex-direction: column;
  }
}

.box-selector-item.limited.business {
  --selected-item-color: var(--BE-only);
}

.box-selector-item.limited.business label {
  border-color: var(--BE-only);
  border-width: 2px;
}

.box-selector-item .limited-icon {
  position: absolute;
  left: 1em;
  top: calc(50% - 0.5em);
  height: 1em;
}

@media (min-width: 992px) {
  .box-selector-item .limited-icon {
    left: 2em;
  }
}

.box-selector-item.limited.business :checked + label {
  color: initial;
}
